import fetch from "isomorphic-fetch"
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"
import { useStore } from "react-redux"
import { store } from "../store"

let cachedApiKey = null

const getApiKey = async () => {
  if (cachedApiKey) {
    return cachedApiKey
  }
  const data = await // await fetch("http://localhost:7071/api/api")
  (
    await fetch("https://euwqsub0oeazf02.azurewebsites.net/api/api")
  ).json()
  cachedApiKey = data.apiKey
  return cachedApiKey
}

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: "https://qa.np.api.ey.com/cda/v1/",
    fetch: async (uri, options) => {
      // const store = useStore()
      const msalToken = store.getState().users.users[2]
      options.headers.Authorization = `${msalToken}`
      options.headers.apiKey = await getApiKey()
      return fetch(uri, options)
    },
  }),
})
export default client

// import fetch from "isomorphic-fetch"
// import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"
// import { useStore } from "react-redux"
// import { store } from "../store"

// const getApiKey = async () => {
//   const data = await (
//     await fetch("http://localhost:7071/api/api")
//     // await fetch("https://euwqsub0oeazf02.azurewebsites.net/api/api")
//   ).json()
//   return data.apiKey
// }

// export const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: new HttpLink({
//     uri: "https://qa.np.api.ey.com/cda/v1/",
//     fetch: async (uri, options) => {
//       // const store = useStore()
//       const msalToken = store.getState().users.users[2]
//       options.headers.Authorization = `${msalToken}`
//       options.headers.apiKey = await getApiKey()
//       return fetch(uri, options)
//     },
//   }),
// })
// export default client
